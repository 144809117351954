<template>
  <div class="scrollable">
    <div style="background-color: #fbfbfb" v-loading="pageLoading">
      <div class="row justify-content-between page-title">
        <div class="col-sm-12 col-md-5">
          <div id="profile" class="mnotify-card">
            <div id="side-indicators-content" class="mnotify-card-header">
              <h3 class="mnotify-card-header-text py-0 my-0">
                {{ $t("dashboard.account_detail.header") }}
              </h3>
            </div>
            <div class="mnotify-card-body">
              <div class="profile-inner">
                <div class="avatar">
                  <p class="avatar-text">
                    {{ userAvatarLetters }}
                  </p>
                </div>
                <div class="email">
                  <p>{{ getUsername }}</p>
                </div>
                <div class="upload-information">
                  <button
                    id="update-information"
                    v-if="!profileUpdated && !errors"
                    @click="editUserProfile = false"
                    class="btn"
                    data-toggle="modal"
                    data-target="#exampleModal">
                    {{ $t("dashboard.account_detail.update") }}
                  </button>
                </div>
                <div
                  class="row"
                  v-if="!corporate && Object.keys(userProfile).length"
                  id="user__profile__data"
                  style="width: 100%">
                  <div class="col-md-12">
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-6">
                        <div class="__profile__title mb-2">First Name</div>
                        <div class="__profile__text notranslate">
                          {{ userProfile.firstname }}
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="__profile__title mb-2">Last Name</div>
                        <div class="__profile__text notranslate">
                          {{ userProfile.lastname }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-12">
                        <div class="__profile__title mb-2">Date of Birth</div>
                        <div class="__profile__text">
                          <!-- Catch invalid dates here and set them to Not Set -->
                          {{
                            moment(userProfile.date_of_birth).format(
                              "MMMM DD, YYYY"
                            ) == "Invalid date"
                              ? "Not Set"
                              : moment(userProfile.date_of_birth).format(
                                  "MMMM DD,YYYY"
                                )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-12">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.mobile") }}
                        </div>
                        <div class="__profile__text">
                          {{ userProfile.phone }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.email") }}
                        </div>
                        <div class="__profile__text">
                          {{ userProfile.email }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <button
                        class="edit_btn mt-3"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        @click.prevent="showEditIndividualProfile">
                        {{ $t("dashboard.account_detail.link") }}
                        <i class="fas fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="corporate && Object.keys(corporateData).length"
                  id="user__profile__data"
                  style="width: 100%">
                  <div class="col-md-12">
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-6">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.corporate") }}
                        </div>
                        <div class="__profile__text">
                          {{ corporateData.name }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-12">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.dob") }}
                        </div>
                        <div class="__profile__text">
                          {{
                            moment(corporateData.date_of_est).format(
                              "MMMM DD, YYYY"
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4 justify-content-between">
                      <div class="col-md-12">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.mobile") }}
                        </div>
                        <div class="__profile__text">
                          {{ corporateData.phone }}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div class="col-md-6">
                        <div class="__profile__title mb-2">
                          {{ $t("dashboard.account_detail.email") }}
                        </div>
                        <div class="__profile__text">
                          {{ corporateData.email }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <button
                        class="edit_btn mt-3 notranslate"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        @click.prevent="showEditCorporateProfile">
                        {{ $t("misc.button.edit") }}
                        <i class="fas fa-pen"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-7">
          <div class="row">
            <router-view />
          </div>
        </div>
      </div>
      <!-- UPDATE PROFILE MODAL -->
      <div
        class="modal fade profile-modal modal-form-container"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-profile"
          role="dialog">
          <div class="modal-content">
            <div class="modal-header pt-4 pb-2">
              <h5 class="modal-title" id="exampleModalLabel">
                {{
                  editUserProfile
                    ? "Edit Individual Profile"
                    : "Update information"
                }}
              </h5>
              <div class="d-flex">
                <h5 class="update-type">
                  {{ editUserProfile ? "" : updateType }}
                </h5>
                <button class="close__modal" @click="closeModalBtn">X</button>
              </div>
            </div>
            <div class="modal-body m-0 p-0 modal-body-state">
              <!-- INTITIAL UPDATE INFORMATION STATE -->
              <div
                class="row justify-content-center align-items-center modal-body-intro m-0 p-0"
                v-if="updateinformationState === 'initial'">
                <div class="col-md-10">
                  <h3 class="main-info-text">
                    {{ $t("dashboard.onboarding.update_information.what_use") }}
                  </h3>
                  <div class="d-flex justify-content-between mt-5">
                    <button class="info-btn" @click="individualBtn">
                      {{
                        $t("dashboard.onboarding.update_information.individual")
                      }}
                    </button>
                    <button class="info-btn" @click="cooperateBtn">
                      {{
                        $t("dashboard.onboarding.update_information.corporate")
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <!-- INDIVIDUAL ACCOUNTS CREATION -->
              <div
                class="row justify-content-center modal-body-intro mt-2"
                v-if="updateinformationState === 'individual'"
                style="">
                <div class="col-11">
                  <el-form
                    ref="ruleForm"
                    :model="formData"
                    :rules="rules"
                    @submit.prevent.native="checkEnter">
                    <div class="row justify-content-center">
                      <div class="col-md-6">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.first_name.label'
                              )
                            "
                            prop="firstname">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.first_name.placeholder'
                                )
                              "
                              v-model="formData.firstname"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.last_name.label'
                              )
                            "
                            prop="lastname">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.last_name.placeholder'
                                )
                              "
                              v-model="formData.lastname"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12 my-0 py-0">
                        <el-form-item
                          style="margin: 0; padding: 0"
                          :label="
                            $t(
                              'dashboard.onboarding.update_information.form.birthday.label'
                            )
                          "></el-form-item>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container mb-2">
                          <el-form-item
                            style="width: 100%; margin: 0; padding: 0"
                            prop="date_of_birth">
                            <el-date-picker
                              v-model="formData.date_of_birth"
                              type="date"
                              style="width: 100%"
                              format="yyyy-MM-dd"
                              placeholder="1999-02-05"
                              popper-class="notranslate">
                            </el-date-picker>
                          </el-form-item>
                        </div>
                      </div>
                      <!-- <div class="col-4 col-sm-4 col-md-4">
                                                <div class="form_input_container">
                                                    <el-form-item  prop="day">
                                                        <el-date-picker
                                                            style="width:100%;"
                                                            v-model="formData.day"
                                                            type="date"
                                                            format="dd"
                                                            placeholder="Day">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                            <div class=" col-4 col-sm-4 col-md-4">
                                                <div class="form_input_container">
                                                    <el-form-item  prop="month">
                                                        <el-date-picker
                                                            style="width:100%"
                                                            v-model="formData.month"
                                                            type="month"
                                                            format="MM"
                                                            placeholder="Month">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </div>
                                            </div>
                                            <div class="col-4 col-sm-4 col-md-4">
                                                <div class="form_input_container">
                                                    <el-form-item  prop="year">
                                                        <el-date-picker
                                                            v-model="formData.year"
                                                            type="year"
                                                            style="width:100%"
                                                            format="yyyy"
                                                            placeholder="Year">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </div>
                                                                                                                </div> -->
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.industry.label'
                              )
                            "
                            prop="industry_id">
                            <el-select
                              style="width: 100%"
                              v-model="formData.industry_id"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.industry.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(ind, key) in industries"
                                :key="key"
                                :label="`${ind.industry_name}`"
                                :value="ind.id"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.heard_about_us.label'
                              )
                            "
                            prop="heard_about_us">
                            <el-select
                              style="width: 100%"
                              v-model="formData.heard_about_us"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.label'
                                )
                              ">
                              <el-option
                                v-for="(list, key) in listOfOptions"
                                :key="key"
                                :label="`${list.value}`"
                                :value="list.value"></el-option>
                            </el-select>
                            <!-- Insert option to input whatever here -->
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.custom'
                                )
                              "
                              v-model="formData.heard_about_us_other"
                              v-if="
                                formData.heard_about_us === 'Other'
                              "></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <span v-if="errors.length">
                      <Alert
                        v-for="(error, key) in errors"
                        :key="key"
                        :message="error[0]"
                        :type="type" />
                    </span>
                    <div class="row justify-content-between mb-5">
                      <div
                        class="col-md-12 d-flex justify-content-between pt-3">
                        <el-button
                          v-if="profileUpdated"
                          @click="closeModalBtn"
                          id="back"
                          >{{ $t("misc.button.close") }}</el-button
                        >
                        <el-button v-else @click="back" id="back"
                          >{{ $t("misc.button.back") }}
                        </el-button>
                        <el-button
                          v-if="editUserProfile"
                          style="float: right"
                          id="continue"
                          :loading="loading"
                          type="primary"
                          @click="editIndividualProfile('ruleForm')"
                          >{{ $t("misc.button.edit") }}</el-button
                        >
                        <el-button
                          v-if="!editUserProfile"
                          id="continue"
                          :loading="loading"
                          type="primary"
                          @click="submitIndividualForm('ruleForm')">
                          {{ $t("misc.button.continue") }}</el-button
                        >
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
              <!-- CO-OPERATE ACCOUNT CREATION -->
              <div
                class="row justify-content-center modal-body-intro"
                v-if="updateinformationState === 'cooperate'">
                <div class="col-11">
                  <el-form
                    :model="formData"
                    :rules="coperateFormRules"
                    ref="coperatForm"
                    @submit.prevent.native="checkEnter">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.company_name.label'
                              )
                            "
                            prop="name">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.company_name.placeholder'
                                )
                              "
                              v-model="formData.name"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12 my-0 py-0">
                        <el-form-item
                          style="margin: 0; padding: 0"
                          required
                          :label="
                            $t(
                              'dashboard.onboarding.update_information.form.date_of_establishment.label'
                            )
                          "></el-form-item>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container mb-2">
                          <el-form-item
                            style="width: 100%; margin: 0; padding: 0"
                            prop="date_of_birth">
                            <el-date-picker
                              v-model="formData.date_of_est"
                              type="date"
                              style="width: 100%"
                              format="yyyy-MM-dd"
                              placeholder="1999-02-05"
                              popper-class="notranslate">
                            </el-date-picker>
                          </el-form-item>
                        </div>
                      </div>
                      <!-- <div class="col-4 col-sm-4 col-md-4">
                                            <div class="form_input_container">
                                                <el-form-item  prop="day">
                                                    <el-date-picker
                                                        style="width:100%;"
                                                        v-model="formData.day"
                                                        type="date"
                                                        format="dd"
                                                        placeholder="Day">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 col-md-4">
                                            <div class="form_input_container">
                                                <el-form-item  prop="month">
                                                    <el-date-picker
                                                        style="width:100%"
                                                        v-model="formData.month"
                                                        type="month"
                                                        format="MM"
                                                        placeholder="Month">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                        </div>
                                        <div class="col-4 col-sm-4 col-md-4">
                                            <div class="form_input_container">
                                                <el-form-item  prop="year">
                                                    <el-date-picker
                                                        v-model="formData.year"
                                                        type="year"
                                                        style="width:100%"
                                                        format="yyyy"
                                                        placeholder="Year">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </div>
                                                                                                            </div> -->
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.industry.label'
                              )
                            "
                            prop="industry_id">
                            <el-select
                              style="width: 100%"
                              v-model="formData.industry_id"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.industry.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(ind, key) in industries"
                                :key="key"
                                :label="`${ind.industry_name}`"
                                :value="ind.id">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.heard_about_us.label'
                              )
                            "
                            prop="heard_about_us">
                            <el-select
                              style="width: 100%"
                              v-model="formData.heard_about_us"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(list, key) in listOfOptions"
                                :key="key"
                                :label="`${list.value}`"
                                :value="list.value"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-between my-5">
                      <div class="col-md-12" v-if="errors.length">
                        <Alert
                          v-for="(error, key) in errors"
                          :key="key"
                          :message="error[0]"
                          :type="type" />
                      </div>
                      <div class="col-md-12 d-flex justify-content-between">
                        <el-button
                          v-if="profileUpdated"
                          @click="closeModalBtn"
                          id="back"
                          >{{ $t("misc.button.close") }}</el-button
                        >
                        <el-button v-else @click="back" id="back">{{
                          $t("misc.button.back")
                        }}</el-button>
                        <el-button
                          v-if="!editCorporateData"
                          :loading="loading"
                          @click="submitCoperatForm('coperatForm')"
                          id="continue"
                          type="primary"
                          >{{ $t("misc.button.save") }}</el-button
                        >
                        <el-button
                          v-if="editCorporateData"
                          :loading="loading"
                          @click="editCoporateData('coperatForm')"
                          id="continue"
                          type="primary"
                          >{{ $t("misc.button.edit") }}</el-button
                        >
                      </div>
                    </div>
                  </el-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END OF UPDATE PROFILE MODAL -->
    </div>
  </div>
</template>
<script>
  import store from "@/state/store.js";
  import formatInputDate from "@/helpers/formatInputDate";
  import $ from "jquery";
  import togglerCorporateStatus from "@/helpers/toggleCorporateStatus.js";
  import corporate from "@/helpers/corporate";
  export default {
    name: "profile",
    data() {
      return {
        updateinformationState: "initial",
        profileUpdated: false,
        imageUrl: "",
        updateType: "",
        fileError: "",
        errors: [],
        percentage: 0,
        loading: false,
        showUploadedImage: false,
        showProgress: false,
        type: "alert-danger",
        colors: [
          { color: "#F7921C", percentage: 0 },
          { color: "#F7921C", percentage: 20 },
          { color: "#F7921C", percentage: 40 },
          { color: "#F7921C", percentage: 60 },
          { color: "#F7921C", percentage: 80 },
          { color: "#F7921C", percentage: 100 },
        ],
        listOfOptions: [
          {
            value: "Through A friend",
          },
          {
            value: "Social Media",
          },
          {
            value: "Linkedin",
          },
          {
            value: "Google",
          },
          {
            value: "Youtube",
          },
          {
            value: "Advertisement",
          },
          {
            value: "Other",
          },
        ],
        userDataForm: {},
        corporateData: {
          name: "",
          date_of_est: "",
          date_of_birth: "",
          email: "",
          phone: "",
          id: "",
          heard_about_us: "",
          industry_id: "",
          bus_cert: "",
        },
        userProfile: {
          firstname: "",
          lastname: "",
          date_of_birth: "",
          heard_about_us: "",
          industry_id: "",
          phone: "",
          email: "",
        },
        industries: [],
        pageLoading: false,
        userProfileData: false,
        editUserProfile: false,
        editCorporateData: false,
        formData: {
          firstname: "",
          lastname: "",
          name: "",
          heard_about_us: "",
          heard_about_us_other: "",
          date_of_est: "",
          bus_cert: "",
          industry_id: "",
          bus_cert: "",
        },
        currentUser: {},
        rules: {
          firstname: [
            {
              required: true,
              message: "Please Enter Your First Name.",
              trigger: "change",
            },
          ],
          lastname: [
            {
              required: true,
              message: "Please Enter Your Last Name.",
              trigger: "change",
            },
          ],
          date_of_birth: [
            {
              required: false,
              message: "Please this field is required",
              trigger: "change",
            },
          ],
          heard_about_us: [
            {
              required: true,
              message: "Please this field is required",
              trigger: "change",
            },
          ],
          industry_id: [
            {
              required: true,
              message: "Please this field is required",
              trigger: "change",
            },
          ],
          email: [
            {
              required: true,
              message: "Your Email is required",
              trigger: "change",
            },
          ],
        },
        coperateFormRules: {
          name: [
            {
              required: true,
              message: "Please enter your company's name",
              trigger: "change",
            },
          ],
          date_of_est: [
            {
              required: true,
              message: "Please this field is required",
              trigger: "change",
            },
          ],
          bus_cert: [
            {
              required: true,
              message: "Please enter your company's name",
              trigger: "change",
            },
          ],
          heard_about_us: [
            {
              // required: true,
              // message: "Please Upload business certificate",
              trigger: "change",
            },
          ],
          industry_id: [
            {
              required: true,
              message: "Please this field is required",
              trigger: "change",
            },
          ],
          email: [
            {
              required: true,
              message: "Your Email is required",
              trigger: "change",
            },
          ],
        },
      };
    },
    computed: {
      checkProfileStatus() {},
      userAvatarLetters() {
        let firstLetter,
          secondLetter = "";
        if (this.currentUser.profile_type === 2) {
          firstLetter = this.currentUser.firstname
            ? this.currentUser.firstname.charAt(0).toUpperCase()
            : "";
          secondLetter = this.currentUser.lastname
            ? this.currentUser.lastname.charAt(0).toUpperCase()
            : "";
        } else {
          firstLetter =
            this.currentUser.name && this.currentUser.name.split(" ")[0]
              ? this.currentUser.name.split(" ")[0].charAt(0).toUpperCase()
              : "";
          secondLetter =
            this.currentUser.name && this.currentUser.name.split(" ")[1]
              ? this.currentUser.name.split(" ")[1].charAt(0).toUpperCase()
              : "";
        }
        return `${firstLetter}${secondLetter}`;
      },
      getUsername() {
        if (!this.currentUser) return "";
        return this.currentUser.name;
      },
      corporate() {
        return corporate();
      },
    },
    mounted() {
      store.dispatch("auth/getUserProfileStatus").then((res) => {
        let userData = res.data.data;
        if (userData.profile_type === 2) {
          userData.name = `${userData.firstname} ${userData.lastname}`;
        }
        this.currentUser = userData;
      });
      store
        .dispatch("industries/getIndustryReporting")
        .then((res) => (this.industries = res));
      store
        .dispatch("auth/viewCorporateProfile")
        .then((res) => {
          this.profileUpdated = true;
          this.corporateData = { ...res.data.data };
        })
        .catch(() => {});
      this.pageLoading = true;
      store
        .dispatch("auth/viewIndividualProfile")
        .then((response) => {
          //this.clearStorage()
          this.pageLoading = true;
          this.profileUpdated = true;
          if (response.data.data) {
            this.userProfileData = true;
            this.userProfile = { ...response.data.data };
          }
        })
        .finally(() => (this.pageLoading = false));
    },
    methods: {
      back() {
        this.updateinformationState = "initial";
        this.loading = false;
        this.errors = [];
        this.formData = {
          firstname: "",
          lastname: "",
          day: "",
          month: "",
          year: "",
          name: "",
          heard_about_us: "",
          date_of_est: "",
          bus_cert: "",
          industry_id: "",
          bus_cert: "",
        };
        this.corporateData = {
          firstname: "",
          lastname: "",
          day: "",
          month: "",
          year: "",
          name: "",
          heard_about_us: "",
          date_of_est: "",
          bus_cert: "",
          industry_id: "",
          bus_cert: "",
        };
      },
      url() {
        return process.env.VUE_APP_ENGINE_URL;
      },
      showEditCorporateProfile() {
        this.formData = { ...this.corporateData };
        this.imageUrl = `${process.env.VUE_APP_ENGINE_URL}/storage/bus_certs/${this.corporateData.bus_cert}`;
        this.editCorporateData = true;
        this.updateinformationState = "cooperate";
        this.updateType = "Coporate account";
      },
      showEditIndividualProfile() {
        this.formData = { ...this.userProfile };
        // Current implementation is to catch the default value and set it to null so that the datepicker can be empty
        if (this.formData.date_of_birth == "0000-00-00") {
          this.formData.date_of_birth = "";
        }
        this.editUserProfile = true;
        this.individualBtn();
      },
      removeUploadedImage(e) {
        this.showUploadedImage = false;
        this.formData.bus_cert = "";
        this.showProgress = false;
        this.imageUrl = "";
        e.preventDefault();
      },
      handleFileChange(event) {
        let file = this.$refs.certificate ? this.$refs.certificate.files : "";
        if (Object.keys(file).length !== 0) {
          let actualFile = file[0];
          this.formData.bus_cert = actualFile;
          this.showProgress = true;
          this.imageUrl = URL.createObjectURL(actualFile);
        }
      },
      handleUploadFile() {
        this.handleFileChange();
        document.getElementById("fileUpload").click();
        this.imageUrl = "";
      },
      individualBtn() {
        this.updateinformationState = "individual";
        this.updateType = "Personal account";
      },
      cooperateBtn(data = "Coporate account") {
        this.updateinformationState = "cooperate";
        this.updateType = data;
      },
      closeModalBtn() {
        this.updateType = "";
        this.updateinformationState = "initial";
        this.editUserProfile = false;
        $("#exampleModal").modal("hide");
      },
      resetModal() {},
      showModal() {},
      editCoporateData(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let { date_of_est, heard_about_us, name, bus_cert, industry_id } =
              this.formData;
            let date = formatInputDate(date_of_est);
            //CREATE FORM DATA
            // if(Object.keys(bus_cert).length == 0) {
            //     this.fileError = "Please upload business certificate."
            // }
            let formData = new FormData();
            formData.append("name", name);
            formData.append("date_of_est", date);
            formData.append("bus_cert", null);
            formData.append("heard_about_us", heard_about_us);
            formData.append("industry_id", industry_id);
            // return;
            store
              .dispatch("auth/editCoporateProfile", formData)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                togglerCorporateStatus(true);
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push("An error occured try again.");
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            // if (!this.formData.bus_cert) {
            //   this.fileError = "Please upload business certificate.";
            // }
            return false;
          }
        });
      },
      editIndividualProfile(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let {
              date_of_birth,
              heard_about_us,
              firstname,
              lastname,
              industry_id,
            } = this.formData;
            // if user does not want to set their date of birth, use a defualt value for them,
            let date =
              date_of_birth == null
                ? Date("0001", "00", "00")
                : formatInputDate(date_of_birth);
            // If heard about us is other, get the value from the other field
            heard_about_us =
              heard_about_us == "Other"
                ? this.formData.heard_about_us_other !== ""
                  ? this.formData.heard_about_us_other
                  : "Other"
                : heard_about_us;

            let payload = {
              firstname: firstname,
              lastname: lastname,
              heard_about_us: heard_about_us,
              date_of_birth: date,
              industry_id: industry_id,
            };
            store
              .dispatch("auth/editIndividualProfile", payload)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push("An error occured try again.");
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      submitIndividualForm(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let {
              date_of_birth,
              heard_about_us,
              firstname,
              lastname,
              industry_id,
            } = this.formData;
            // if heard about us is equal to 'Other' then set heard_about_us to the value of this.formData.heard_about_us_other else set it to the value of heard_about_us
            this.formData.heard_about_us =
              heard_about_us == "Other"
                ? this.formData.heard_about_us_other !== ""
                  ? this.formData.heard_about_us_other
                  : "Other"
                : heard_about_us;
            let date = formatInputDate(date_of_birth);
            let payload = {
              firstname: firstname,
              lastname: lastname,
              heard_about_us: heard_about_us,
              date_of_birth: date,
              industry_id: industry_id,
            };

            store
              .dispatch("auth/submitIndividualProfile", payload)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
                togglerCorporateStatus(false);
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push("An error occured try again.");
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      submitCoperatForm(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let { date_of_est, heard_about_us, name, bus_cert, industry_id } =
              this.formData;
            let date = formatInputDate(date_of_est);
            //CREATE FORM DATA
            let formData = new FormData();
            formData.append("name", name);
            formData.append("date_of_est", date);
            formData.append("bus_cert", null);
            formData.append("heard_about_us", heard_about_us);
            formData.append("industry_id", industry_id);

            store
              .dispatch("auth/submitCoperateProfile", formData)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                togglerCorporateStatus(true);
                setTimeout(function () {
                  window.location.reload();
                }, 1000);
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push("An error occured try again.");
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            // if (!this.formData.bus_cert) {
            //   this.fileError = "Please upload business certificate.";
            // }
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped lang="scss">
  .__profile__title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #575761;
  }

  .__profile__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #575761;
  }

  #profile {
    min-height: 100vh;
  }

  .close__modal {
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: bolder;
    cursor: pointer;
    margin-left: 1em;
    padding-right: 0px;
  }

  .upload-information {
    width: 100%;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    color: #000;
  }

  .profile-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102.2px;
    height: 102.2px;
    background: #979797;
    opacity: 0.2;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .avatar-text {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px !important;
    color: #484a4f;
    margin-bottom: 0px !important;
  }

  .email p {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #575761;
  }

  #update-information {
    width: 100%;
    height: 40px;
    background: #f7921c;
    border-radius: 5px;
    color: #f6f6f6;
    outline: none;
    border: none;
    transition: all 0.5s;
  }

  #update-information:hover {
    outline: none !important;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .main-info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #484a4f;
  }

  .info-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 5px;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #9e9ea7;
    border: none;
  }

  .info-btn:hover {
    background: #f7921c;
    color: #fff;
  }

  .modal-body-intro {
    min-height: 700px;
  }

  .__align__items {
    align-items: center;
  }

  .update-type {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding-top: 0.5em;
    color: #575761;
  }

  #back {
    width: 30%;
    border: 5px;
    height: 40px;
    background: #f5f5f5;
    border: none;
    outline: none;
    color: #9e9ea7;
  }

  #continue {
    width: 40%;
    border: 5px;
    height: 40px;
  }

  .business-certificate {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #575761;
  }

  .business-descriptions {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    color: #484a4f;
  }

  .upload-certificate {
    height: 50px;
    width: 193px;
    background: #f6f6f6;
    border-radius: 5px;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #979797;
    outline: none;
    border: none;
  }

  #fileUpload {
    display: none;
  }

  .fileUploadContainer {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;

    .pdf {
      width: 100%;
      height: 100%;
    }

    #uploadedImage {
      width: 193px;
      height: 129px;
      border-radius: 5px;
      background-color: #979797;
    }
  }

  .progressContainer {
    display: flex;
    align-items: center;
    margin-left: 1em;

    .times {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.5px solid #ff5656;
      color: #ff5656;
      font-size: 0.5em;
    }

    .progressText {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #f7921c;
      margin-left: 0.5em;
      padding-top: 1em;
    }
  }

  .bordered {
    border: 1px solid#FF5656 !important;
  }

  .user__profile__data {
    input {
      padding: 5px !important;
    }
  }

  .edit_btn {
    color: #f7921c;
    font-size: 0.7em;
    border: none;
    outline: none;
    background: transparent;
    display: block;
  }

  // progress content
  @media screen and (max-width: 768px) and (min-width: 400px) {
    #update-information {
      width: 240px;
      height: 38px;
    }
  }

  .profile-modal {
    max-height: 100vh;
  }

  .modal-dialog-profile {
    height: auto;
    margin: 0 auto !important;
    padding: 0 !important;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 88vh;
    overflow: auto;
  }

  .modal-body-state {
    height: auto !important;
    overflow: auto;
  }

  .modal-body-state::-webkit-scrollbar {
    display: none;
  }

  .contains-email-edit {
    display: block;
    flex: 1;
  }

  .edit_btn {
    display: block;
    margin: 0;
    padding: 0;
    padding-top: 1rem;
  }

  .modal-form-container {
    overflow: auto;
  }

  .scrollable {
    overflow-y: hidden;
    height: 88vh;
    overflow-x: hidden;
    /* padding-bottom: calc(20vh - 100px); */
  }

  @media screen and (max-width: 500px) {
    .scrollable {
      overflow-y: scroll;
    }
  }
</style>
